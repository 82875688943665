import React from 'react';
import { Nav } from 'react-bootstrap';
import MBFlyout from './mbFlyout';
import MBIcon from '../app/mbIcon';
import { Size } from '../../util/bootstrapUtil';
import { buildLogoutUrl } from '../../config/configuration';

const LOGOUT_URL = buildLogoutUrl();

export interface MBNavigationProps {
    onClose: () => void;
}

const MBNavigationFlyout: React.FC<MBNavigationProps> = (props) => {
    const { onClose } = props;

    return (
        <MBFlyout title="Menu Options" onClose={onClose}>
            <Nav className="flyout-navigation">
                <a href={LOGOUT_URL}
                        className="nav-link d-flex align-items-center"
                        onClick={onClose}>
                    <MBIcon icon="logout" size={Size.EXTRA_LARGE}  className="me-2" />
                    Logout
                </a>
            </Nav>
        </MBFlyout>
    );
};

export default MBNavigationFlyout;
