import {
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    ICognitoUserData
} from "amazon-cognito-identity-js";
import { ApiResponseHandler, ApiTokenResponse, AppThunk, AppUser, CognitoUserTokens, Nullable } from "../../../@types";
import { buildLogoutUrl, getConfiguration } from '../../../config/configuration';
import UserService from '../../../service/user/userService';
import { accessTokenRefresh, accessTokenRefreshSuccess, initializeApp, initializeAppSuccess, setRedirectPath, userLogout, userLogoutSuccess } from "../../app/appState";

const MIN_TOKEN_REFRESH_MINUTES = 15;
const { userPoolId, clientId } = getConfiguration();
const LOGOUT_URL = buildLogoutUrl();

export function initializeAppAction(): AppThunk {
    return (dispatch, getState) => {
        const { isLoading, isInitialized } = getState().AppState;

        if (isLoading || isInitialized) {
            return;
        }

        dispatch(initializeApp());
        const apiTokens = UserService.getLastUserTokens();

        const appUser: Nullable<AppUser> = apiTokens != null ? createAppUserFromApiTokens(apiTokens) : null;

        if (!appUser) {
            dispatch(initializeAppSuccess({ appUser: appUser, isLoginFailed: false }));
            return;
        }

        const handler: ApiResponseHandler<string> = {
            onSuccess: () => {
                    dispatch(initializeAppSuccess({ appUser: appUser, isLoginFailed: false }));
            },
            onError: (err) => {
                console.error(err);
                dispatch(initializeAppSuccess({ appUser, isLoginFailed: true }));
            },
        };

        dispatch(refreshToken(appUser, handler));
    };
}
export function createCognitoUserFromAppUser(appUser:AppUser, userPoolId:string, clientId:string):Nullable<CognitoUser> {
    return createCognitoUser(userPoolId, clientId, appUser);
}

function createAppUserFromApiTokens(apiTokens:Nullable<ApiTokenResponse>):Nullable<AppUser> {
    const tokens = createUserTokensFromApiTokens(apiTokens);
    if (!tokens) {
        return null;
    }

    const idTokenPayload = tokens.idToken.decodePayload();
    const username = idTokenPayload['cognito:username'];
    const userType = idTokenPayload['custom:userType'];

    return {username, userType, tokens};
}
 
function createUserTokensFromApiTokens(apiTokens:Nullable<ApiTokenResponse>):Nullable<CognitoUserTokens> {
    if (apiTokens == null) {
        return null;
    }

    return {
        idToken : new CognitoIdToken({IdToken : apiTokens.id_token}),
        accessToken : new CognitoAccessToken({AccessToken: apiTokens.access_token}),
        refreshToken : new CognitoRefreshToken({RefreshToken: apiTokens.refresh_token})
    }
}

function createCognitoUser(
        userPoolId:string,
        clientId:string,
        appUser:Nullable<AppUser>):Nullable<CognitoUser> {
    if (appUser == null) {
        return null;
    }

    const userPool = new CognitoUserPool({
        UserPoolId: userPoolId, 
        ClientId : clientId
    });

    const userData:ICognitoUserData = {
        Username : appUser.username,
        Pool : userPool
    };
        
    const cognitoTokens = appUser.tokens;
    const user = new CognitoUser(userData);
    const userSession = new CognitoUserSession({
        AccessToken : cognitoTokens.accessToken,
        IdToken : cognitoTokens.idToken,
        RefreshToken : cognitoTokens.refreshToken
    });

    user.setSignInUserSession(userSession);
    return user;
}

function isAccessTokenRefreshNeeded(appUser: AppUser): boolean {
    const userAccessToken = appUser.tokens.accessToken;
    const expiration = userAccessToken.getExpiration();
    const currentTime = new Date().getTime() / 1000;
    const minutesUntilExpired = (expiration - currentTime) / 60;

    return minutesUntilExpired <= MIN_TOKEN_REFRESH_MINUTES;
}

export function refreshToken(appUser: AppUser, handler?: ApiResponseHandler<string>): AppThunk {
    return (dispatch) => {
        if (!isAccessTokenRefreshNeeded(appUser)) {
            return handler?.onSuccess(appUser.tokens.accessToken.getJwtToken());
        }

        const endSession = () => {
            dispatch(logout());
        };

        dispatch(accessTokenRefresh());
        const { userPoolId, clientId } = getConfiguration();
        const cognitoUser = createCognitoUserFromAppUser(appUser, userPoolId, clientId);
        if (!cognitoUser) {
            endSession();
            return handler?.onError('Failed creating cognito user');
        }

        cognitoUser.getSession((error: Nullable<Error>, session: Nullable<CognitoUserSession>) => {
            if (error || !session) {
                endSession();
                return handler?.onError(error?.message ?? 'Failed getting session');
            }

            const cognitoRefreshToken = session.getRefreshToken();
            const refreshHandler = (error: Nullable<Error>, refreshSession: CognitoUserSession) => {
                if (error || !refreshSession) {
                    endSession();
                    return handler?.onError(error?.message ?? 'Failed refreshing session');
                }

                const newAccessToken = refreshSession.getAccessToken();

                const jwtAccessToken = newAccessToken.getJwtToken();
                UserService.saveUserAccessToken(jwtAccessToken);
                dispatch(accessTokenRefreshSuccess(newAccessToken));
                handler?.onSuccess(jwtAccessToken);
            };

            cognitoUser.refreshSession(cognitoRefreshToken, refreshHandler);
        });
    };
}

export function storeRedirectPath(path:Nullable<string>):AppThunk {
    return (dispatch) => {
        if (!path) {
            return;
        }

        dispatch(setRedirectPath(path));
        UserService.saveRedirectPath(path);
    };
}
export function logout(): AppThunk {
    return (dispatch, getState) => {
        const { isLoading, appUser } = getState().AppState;

        if (isLoading || !appUser) {
            return;
        }

        dispatch(userLogout());

        const cognitoUser = createCognitoUserFromAppUser(appUser, userPoolId, clientId);

        const dispatchEndSession = () => {
            dispatch(userLogoutSuccess());
            window.location.href = LOGOUT_URL;
        };

        if (!cognitoUser) {
            dispatchEndSession();
            return;
        }

        cognitoUser.signOut(() => dispatchEndSession());
    };
}