import { ApiResponseHandler, AppThunk, Nullable } from "../../../@types";
import { PermissionSet } from "../../../constants/PermissionSet";
import {
    initializeEmployee,
    loadUserPermissionSetSuccess
} from "../../user/userState";
import b2bUserService from "../../../service/user/b2bUserService";
import { userLogoutSuccess } from "../../app/appState";

export function loadUser(token:Nullable<string>):AppThunk {
    return (dispatch, getState) => {
		
        if (!token) {
            return;
        }

		const userState = getState().UserState;
		if (userState.isInitialized || userState.isLoading) {
			return;
		}

		dispatch(initializeEmployee());

		if (userState.permissionSets == null) {
			const permissionHandler: ApiResponseHandler<PermissionSet[]> = {
				onSuccess: (permissionSet) => dispatch(loadUserPermissionSetSuccess(permissionSet)),
				onError: (error: string) => dispatch(userLogoutSuccess()),
			}
	
			b2bUserService.getUserPermissions(token, permissionHandler);
		}
    }
}
