import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { ApiResponse, Nullable } from "../@types";
import { getConfiguration } from '../config/configuration';
import { logout } from "../state/action/appAction/appAction";
import store from '../state/appStore';
import { parseErrorMessage } from "../util/exceptionUtil";

const DEFAULT_HEADERS:any = {

};

const MB_APP_CONTEXT_HEADER = {
    'X-MB-APP-CONTEXT' : 'AI'
};

class Api {
    private createB2BAxiosInstance(token:Nullable<string>):AxiosInstance {
        const {b2bBaseApiUrl} = getConfiguration();

        const headers = {...DEFAULT_HEADERS, ...MB_APP_CONTEXT_HEADER};
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        return Axios.create({
            baseURL : b2bBaseApiUrl,
            timeout : 30000,
            headers : headers
        });
    }

    private createAWSAxiosInstance(token:string):AxiosInstance {
        const {awsBaseUrl} = getConfiguration();

        const axios = Axios.create({
            baseURL : awsBaseUrl,
            timeout : 30000,
            headers : {
                ...DEFAULT_HEADERS,
                'Authorization' : `Bearer ${token}`
            }
        });

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            // Logout if API gateway responds with 401
            if (error?.response?.status === 401) {
                store.dispatch(logout());
            }
            
            return Promise.reject(error);
        });

        return axios;
    }

    private parseApiResponse(response:AxiosResponse):ApiResponse {
        const responseData = response.data;
        
        if (responseData?.error) {
            return {
                data : null, 
                error: responseData.error
            };
        }
    
        return {
            data: responseData, 
            error: null
        };
    }

    private parseApiError(error:any):any {
        if ('AxiosError' === error?.name) {
            return error.response?.data?.message ?? error.message;
        }

        return error;
    }

    public async performAwsGETRequest(url:string, token:string, params: any = {}):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).get(url, {params});
            return this.parseApiResponse(response);
        }
        catch (error) {
            const apiError:any = this.parseApiError(error);
            return {
                data: null, 
                error: parseErrorMessage(apiError)
            };
        }
    }    

    public async performPOSTRequest(url:string, request:any, token:string):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).post(url, request);
            return this.parseApiResponse(response);
        }
        catch (error) {
            const apiError:any = this.parseApiError(error);
            return {
                data: null, 
                error: parseErrorMessage(apiError)
            };
        }
    }
    
    public async performPUTRequest(url:string, request:any, token:string):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).put(url, request);
            return this.parseApiResponse(response);
        }
        catch (error) {
            const apiError:any = this.parseApiError(error);
            return {
                data: null, 
                error: parseErrorMessage(apiError)
            };
        }
    }

    public async performDELETERequest(url:string, token:string):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).delete(url);
            return this.parseApiResponse(response);
        }
        catch (error) {
            const apiError:any = this.parseApiError(error);
            return {
                data: null, 
                error: parseErrorMessage(apiError)
            };
        }
    }

    public async performB2BGETRequest(url:string, token:Nullable<string>):Promise<ApiResponse> {
        try {
            const response = await this.createB2BAxiosInstance(token).get(url);
            return this.parseApiResponse(response);
        }
        catch (error) {
            const apiError:any = this.parseApiError(error);
            return {
                data: null, 
                error: parseErrorMessage(apiError)
            };
        }
    }
}

export const REQUIRED_TOKEN_MESSAGE = 'An authentication token is required';
export default new Api();