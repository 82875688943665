import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Chat, ChatState, Message } from "../../@types";

const EMPTY_CHAT:Chat = {
    username: "",
    aimodel: "anthropic.claude-3-sonnet-20240229-v1:0",
    timestamp: "",
    updatedTimestamp: "",
    messages: []
};

const INITIAL_STATE:ChatState = {
    isLoading : false,
    isInitialized: false,
    selectedChatTimestamp: "",
    activeChat: EMPTY_CHAT,
    chats : []
};

const chatSlice = createSlice({
    name: 'chat',
    initialState: INITIAL_STATE,
    reducers: {
        fetchChatList: (state) => {
            state.isLoading = true;
        },
        fetchChatListSuccess: (state:ChatState, {payload}:PayloadAction<Array<Chat>>) => {
            state.isLoading = false;
            state.isInitialized = true;
            payload.sort((chatA:Chat, chatB:Chat) => {
                const chatDateA = Date.parse(chatA.updatedTimestamp);
                const chatDateB = Date.parse(chatB.updatedTimestamp);
                return chatDateB - chatDateA;
            });

            state.chats = payload;
        },
        fetchChatListFailure: (state:ChatState) => {
            state.isLoading = false;
            state.isInitialized = false;
            state.chats = [];
        },
        updateSelectedChatByTimestamp: (state:ChatState, {payload}:PayloadAction<string>) => {
            state.selectedChatTimestamp = payload;
            const matchingChat = state.chats.find(userChat => userChat.timestamp === payload);
            if (!!matchingChat) {
                state.activeChat = matchingChat;
            }
        },
        appendChatMessage: (state:ChatState, {payload}:PayloadAction<Message>) => {
            const matchingChat = state.chats.find(userChat => userChat.timestamp === state.activeChat.timestamp);
            if (!matchingChat) {
                state.chats = [EMPTY_CHAT, ...state.chats];
            }
            else {
                matchingChat.messages.push(payload);
                state.activeChat.messages.push(payload);
            }	
        },
        appendChatMessages: (state:ChatState, {payload}:PayloadAction<Array<Message>>) => {
            const matchingChat = state.chats.find(userChat => userChat.timestamp === state.activeChat.timestamp);
            if (!!matchingChat) {
                matchingChat.messages = payload;
                state.activeChat.messages = payload;
            }
        },
        updateActiveChat: (state:ChatState, {payload}:PayloadAction<Chat>) => {
            state.activeChat = payload
        },
        loadNewChat: (state:ChatState) => {
            const today = new Date();
            const todayString = today.toISOString();
            const modifiedEmptyChat = {
                ...EMPTY_CHAT,
                timestamp: todayString,
                updatedTimestamp: todayString
            }
            state.chats = [modifiedEmptyChat, ...state.chats];
            state.activeChat = modifiedEmptyChat;
            state.selectedChatTimestamp = todayString;
        }
    }
});

export const {
    fetchChatList, 
    fetchChatListSuccess, 
    fetchChatListFailure, 
    updateSelectedChatByTimestamp, 
    appendChatMessage, 
    appendChatMessages,
    updateActiveChat,
    loadNewChat
} = chatSlice.actions;

export default chatSlice.reducer;