import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '../../@types';

const INITIAL_STATE:UserState = {
    isLoading : false,
    isInitialized : false,
    emailAddress: "",
    permissionSets: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: INITIAL_STATE,
    reducers: {
        initializeEmployee: (state) => {
            state.isLoading = true;
        },
        initializeEmployeeSuccess: (state, {payload}) => {
            const {emailAddress} = payload;
            state.isLoading = false;
            state.isInitialized = true;
            state.emailAddress = emailAddress;
        },
        initializeEmployeeFailure: (state) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.emailAddress = '';
        },
        loadUserPermissionSetSuccess: (state, {payload}) => {
            state.permissionSets = payload;
        },
        loadUserPermissionSetFailure: (state) => {
            state.permissionSets = [];
        }
    }
});

export const {
    initializeEmployee,
    initializeEmployeeSuccess,
    initializeEmployeeFailure,
    loadUserPermissionSetSuccess,
    loadUserPermissionSetFailure
} = userSlice.actions;
export default userSlice.reducer;