import {ApiResponse, Chat, ChatSaveRequest, Message} from "../../@types";
import Api from "../api";

const BASE_URL = 'chat';

class ChatApi {
    public async sendChatRequest(token:string, messages:Message[]):Promise<ApiResponse> {
        return await Api.performPOSTRequest(BASE_URL, messages, token);
    }

    public async fetchChats(token:string):Promise<ApiResponse> {
        return await Api.performAwsGETRequest(`${BASE_URL}`, token);
    }

    public async saveChat(token:string, request: Chat):Promise<ApiResponse> {
        return await Api.performPOSTRequest(`${BASE_URL}/chat`, request, token);
    }
}

export default new ChatApi();