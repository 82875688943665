import {ApiResponse, ApiResponseHandler, Chat, Message} from "../../@types";
import ChatApi from "../../api/chat/chatApi";
import { handleApiResponse } from "../../util/apiUtil";
import {REQUIRED_TOKEN_MESSAGE} from "../../api/api";

class ChatService {
    public async sendChatRequest(token:string, request:Message[], handler:ApiResponseHandler<Message[]>):Promise<void> {
        if (!token) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await ChatApi.sendChatRequest(token, request);
        handleApiResponse(response, handler);  
    }

    public async getChats(token:string, handler:ApiResponseHandler<Chat[]>):Promise<void> {
        if (!token) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await ChatApi.fetchChats(token);
        handleApiResponse(response, handler);
    }

    public async saveChat(token: string, request:Chat, handler:ApiResponseHandler<Chat>):Promise<void> {
        if (!request || !token) {
            const error: ApiResponse = { data: null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await ChatApi.saveChat(token, request);
        handleApiResponse(response, handler);
    }
}

export default new ChatService();