import React, { useEffect, useRef } from "react";
import { Size } from "../../util/bootstrapUtil";
import MBIcon from "../app/mbIcon";
import { useAppSelector } from "../../hook/appHook";
import ChatLoader from "../app/chatLoader";
import SimpleBar from "simplebar-react";

const USER = "user";
const ME = "me";
const AGENT = "MB AI Agent";

export interface ChatDisplayProps {
	isLoading: boolean
}

const ChatDisplay:React.FC<ChatDisplayProps> = ({isLoading}) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const {activeChat, chats} = useAppSelector((state) => state.ChatState);

	useEffect(() => {
		if (!!activeChat) {
			setTimeout(() => {
				scrollRef.current?.scrollIntoView({
					block: "end"
				});
			}, 50);
		}
		
	}, [chats, activeChat]);

	function replaceWithBr(message:string):string {
		return message.replace(/\n/g, "<br />")
	}

    return (
		<>
			{!activeChat?.messages.length &&
				<div id="chatOutput">
					<div className="no-messages">Begin a new chat</div>
				</div>
				
			}
			{!!activeChat?.messages.length &&
				<SimpleBar id="chatOutput">
					<div className="p-2">
						{activeChat.messages.map((message, index) =>
							<div key={index} className={`chat-output-row ${message.role === USER ? "chat-output-user" : "chat-output-agent"}`}>
								<p className="chat-output-role"><b>{message.role === USER ? ME : AGENT}</b> 
									{message.role !== USER &&
									<MBIcon 
											className="ms-1"
											icon="auto_awesome" 
											size={Size.SMALL}
											color="text-yellow" />
									}
								</p>
								<p dangerouslySetInnerHTML={{__html: replaceWithBr(message.content[0].text)}}></p>
							</div>
						)}
						{!!isLoading && 
							<div className="chat-output-row">
								<ChatLoader />
							</div>
						}
					</div>
					<div ref={scrollRef}></div>
				</SimpleBar>
			}
		</>
    )
}

export default ChatDisplay;