import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from '../component/header';
import Footer from '../component/footer';
import ChatContainer from './chatContainer';
import { loadUser } from '../state/action/user/userAction';
import {loadChatList} from '../state/action/chat/chatAction';
import { useAppDispatch, useAppSelector } from '../hook/appHook';
import { useAccessToken } from '../hook/accessTokenHook';
import { APP_ROOT_TITLE } from '../App';
import ScrollToTop from '../component/app/scrollToTop';

const Home = () => {
    document.title = `${APP_ROOT_TITLE}`;
    
    const dispatch = useAppDispatch();
    const token = useAccessToken();
    const {UserState, ChatState} = useAppSelector((state) => state);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE}`;
        if (token 
                && !UserState.isLoading 
                && !UserState.isInitialized) {
            dispatch(loadUser(token));
        }

		if (token 
				&& !ChatState.isLoading
				&& !ChatState.isInitialized) {
			dispatch(loadChatList(token));
	}

    }, [dispatch, token, UserState]);

    const defaultRoute = <Route path="*" element={<ChatContainer isNavigateOnChange={false} />} />;

    return (
        <BrowserRouter>
            <Header />
            <div className="container-body">
                <Routes>
                    {defaultRoute}
                </Routes>
                <ScrollToTop />
            </div>
            <Footer />
        </BrowserRouter>
    );
};

export default Home;