import React from "react";

const ChatLoader:React.FC<React.HTMLProps<string>> = (props) => {

    return (
		<div className="chat-loader-container">
			<div className="chat-loader-agent">MB AI Agent is chatting</div>
			<div className="chat-loader"></div>
		</div>
        
    );
}
    
export default ChatLoader;