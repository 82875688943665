import { ApiTokenResponse, Nullable } from '../../@types';
import UserDao from '../../persistence/storage/userDao';
import CookieService from './cookieService';

class UserService {

    public getLastUserTokens(): Nullable<ApiTokenResponse> {
        return CookieService.getUserTokens();
    }

    getEmailClientPreference():Nullable<string> {
        return UserDao.retrieveEmailClientPreference();
    }

    getRedirectPath():Nullable<string> {
        return UserDao.retrieveRedirectPath();
    }

    public saveUserAccessToken(accessToken:string) {
        CookieService.saveAccessToken(accessToken);
    }

    saveEmailClientPreference(preference:string) {
        if (!preference) {
            return;
        }

        UserDao.persistEmailClientPreference(preference);
    }

    saveRedirectPath(path:string) {
        return UserDao.persistRedirectPath(path);
    }

    removeRedirectPath() {
        UserDao.removeRedirectPath();
    }
}

export default new UserService();