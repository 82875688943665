import React, { useEffect } from 'react';
import { Nullable } from '../@types';
import { useAccessToken } from '../hook/accessTokenHook';
import { useAppDispatch, useAppSelector } from '../hook/appHook';
import { initializeAppAction, storeRedirectPath } from '../state/action/appAction/appAction';
import Home from './home';
import Login from './login';

const REDIRECT_PARAMETER = 'redirectPath=';

const Main:React.FC<any> = () => {
    const {appUser, isLoading, isInitialized} = useAppSelector((state) => state.AppState);
    const token = useAccessToken();

    const dispatch = useAppDispatch();
    
    let redirectPath:Nullable<string> = null;
    const url = window.location.href;
    const queryParamStart = url.indexOf('?');
    
    useEffect(() => {
        if (!isLoading && !isInitialized) {
            dispatch(initializeAppAction());
            dispatch(storeRedirectPath(redirectPath));
        }
    }, [dispatch, redirectPath, isLoading, isInitialized, token]);

    if (queryParamStart >= 0) {
        const params = url.substring(queryParamStart).split('&');
        for (const param of params) {
            const redirectStart = param.indexOf(REDIRECT_PARAMETER);    

            if (redirectStart >= 0) {
                redirectPath = param.substring(redirectStart + REDIRECT_PARAMETER.length);
            }
        }
    }

    if (appUser && appUser.userType === "employee") {
        return <Home/>
    }

    return <Login />
};

export default Main;