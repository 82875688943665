import { combineReducers } from "redux";
import AppState from '../app/appState';
import UserState from "../user/userState";
import ChatState from "../chat/chatState";

const rootReducer = combineReducers({
    AppState,
    UserState,
	ChatState
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;