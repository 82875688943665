import React, { useState } from "react";
import MBNavigationFlyout from "./flyout/mbNavigationFlyout";
import MobileFooterLink from "./footer/mobileFooterLink";
import { Container, Navbar, Row } from "react-bootstrap";
import ChatListFlyout from "./chat/chatListFlyout";

const Footer = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [isChatListOpen, setIsChatListOpen] = useState<boolean>(false);

    const iconClass = 'text-center m-0 p-0';

    const menuOptions:JSX.Element[] = [];

    const menuLinks = menuOptions.slice(0, 4);
    menuLinks.push(
        <MobileFooterLink key="mobileFooterMenuLink"
                className={iconClass}
                route="#"
                onClick={() => setIsMenuOpen(true)}
                icon="menu"
                label="Menu" />

    );
	menuLinks.push(
        <MobileFooterLink key="mobileFooterChatListLink"
                className={iconClass}
                route="#"
                onClick={() => setIsChatListOpen(true)}
                icon="question_answer"
                label="Chat History" />
    );

    return(
		<>
            <div className="app-footer">
                    Copyright &copy; 2023 - <a href="https://www.martinbros.com">Martin Bros Distributing Co.</a>
            </div>
            <Navbar bg="dark" variant="dark" className="mobile-nav-bar">
                <Container fluid className="p-0">
                    <Row className="w-100 m-0 p-0">
                        {menuLinks}
                    </Row>
                    {isMenuOpen &&
                        <MBNavigationFlyout onClose={() => setIsMenuOpen(false)} />
                    }
					{isChatListOpen &&
						<ChatListFlyout onClose={() => setIsChatListOpen(false)} />
					}
                </Container>
            </Navbar>
		</>
    );
};

export default Footer;