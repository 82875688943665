import { Nullable } from "../../@types";

const STORAGE = window.localStorage;
const EMAIL_CLIENT_PREFERENCE = 'clientEmailPreference';
const REDIRECT_PATH_KEY = 'redirectPath';

class UserDao {
    private prepareJsonData(data:Object|Array<any>):Nullable<string> {
        if (data == null) {
            return null;
        }

        return JSON.stringify(data);
    }

    private parseJsonString(dataString:Nullable<string>) {
        if (!dataString) {
            return null;
        }

        return JSON.parse(dataString);
    }

    private retrieveDataString(key:string) {
        return this.parseJsonString(STORAGE.getItem(key));
    }

    private persistData(key:string, value:Object|Array<any>):void {
        const jsonString = this.prepareJsonData(value);

        if (jsonString) {
            STORAGE.setItem(key, jsonString);
        }
    }

    public removeAllData() {
        STORAGE.clear();
    }

    private removeData(key:string) {
        STORAGE.removeItem(key);
    }
    retrieveEmailClientPreference() {
        return this.retrieveDataString(EMAIL_CLIENT_PREFERENCE);
    }

    retrieveRedirectPath() {
        return this.retrieveDataString(REDIRECT_PATH_KEY);
    }

    persistEmailClientPreference(preference:string) {
        if (!preference.length) {
            return;
        }

        this.persistData(EMAIL_CLIENT_PREFERENCE, preference);
    }

    persistRedirectPath(path:string) {
        this.persistData(REDIRECT_PATH_KEY, path);
    }

    removeRedirectPath() {
        this.removeData(REDIRECT_PATH_KEY);
    }
}

export default new UserDao();