import React, { useState } from "react";
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from '../component/app/image';
import MBIcon from "./app/mbIcon";
import MBNavigationFlyout from "./flyout/mbNavigationFlyout";
import { Size } from "../util/bootstrapUtil";

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return(
        <header className="sticky-top bg-dark">
            <Navbar bg="dark" variant="dark" expand="md" expanded={isNavOpen}>
            <Container fluid className="justify-content-between d-flex">
                <Navbar.Brand className="d-flex">
                    <Link to="/">
                        <Image src="https://cdn.martinbros.com/resources/header-mb-logo.png" alt="MB Logo" className="me-2" width="200" />
                    </Link>
                </Navbar.Brand>
                <Nav className="ms-auto d-md-block d-none">
                    <Button onClick={() => setIsMenuOpen(true)} variant="link" className="nav-link header-link">
                        <MBIcon icon="menu" size={Size.EXTRA_LARGE} color="text-white" />
                    </Button>
                </Nav>
                {isMenuOpen &&
                    <MBNavigationFlyout onClose={() => setIsMenuOpen(false)} />
                }
            </Container>
            </Navbar>
        </header>
    )
}

export default Header;