import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hook/appHook";
import { updateSelectedChatByTimestamp } from "../../state/chat/chatState";
import SimpleBar from "simplebar-react";

const ACTIVE_FLYOUT_CLASS = 'mb-chatlist-flyout';
const ACTIVE_CHAT_CLASS = 'active-chat';

export interface ChatListProps {
	onChatSelected?: () => void
}

const ChatList:React.FC<ChatListProps> = (props) => {
	const dispatch = useAppDispatch();
	const {chats, selectedChatTimestamp, activeChat} = useAppSelector((state) => state.ChatState);

	useEffect(() => {
		const activeClassName = !!props.onChatSelected ? `${activeFlyoutClass}.${ACTIVE_CHAT_CLASS}` : ACTIVE_CHAT_CLASS;
		const activeElement = document.getElementsByClassName(activeClassName);
		if (activeElement.length) {
			setTimeout(() => {
				activeElement[0].scrollIntoView({block: "nearest"});
			}, 50);
		}

	}, [selectedChatTimestamp]);

	function formatDate(date:string):string {
		const options:Record<string, string> = { 
			year: "numeric", 
			month: "long", 
			day: "numeric"
		}

		const newDate = new Date(date);

		return `${newDate.toLocaleDateString(undefined, options)} ${newDate.toLocaleTimeString('en-US')}`;
	}

	function onChatSelected(timestamp:string) {
		dispatch(updateSelectedChatByTimestamp(timestamp));
		props.onChatSelected?.();
	}

	const activeFlyoutClass = !!props.onChatSelected ? ACTIVE_FLYOUT_CLASS : '';

    return (
		<SimpleBar id="chatList">
			{chats.map((chat, index) => {
				return (
					<div 
							id={`chatRow-${index}`}
							key={index} 
							className={`mb-chat-row ${chat.timestamp === activeChat.timestamp ? `${activeFlyoutClass} ${ACTIVE_CHAT_CLASS}` : ''}`} 
							onClick={() => onChatSelected(chat.timestamp)}>
						<p className="mb-chat-title">
							{!!chat.messages.length &&
								chat.messages[chat.messages.length - 1].content[0].text.substring(0, 100)
							}
							{!chat.messages.length && "New Chat"}
							</p>
						<p className="mb-chat-date">{formatDate(chat.updatedTimestamp)}</p>
					</div>
				)
			})}
		</SimpleBar>
    )
}

export default ChatList;