import { ApiResponseHandler, AppThunk, Chat, Message } from "../../../@types";
import ChatService from "../../../service/chat/chatService";
import {fetchChatList, fetchChatListSuccess, fetchChatListFailure, appendChatMessages, updateActiveChat} from "../../chat/chatState";

export function loadChatList(token:string):AppThunk {
    return (dispatch, getState) => {
        const chatState = getState().ChatState;
        if (!!chatState.chats.length || chatState.isLoading ) {
            return;
        }

        dispatch(fetchChatList());

        const handler:ApiResponseHandler<Array<Chat>> = {
            onSuccess: (chats) => dispatch(fetchChatListSuccess(chats)),
            onError: (error:string) => dispatch(fetchChatListFailure())
        }

        ChatService.getChats(token, handler);
    }
}

export function handleUserChatMessage(token:string, message:Message, callbackHandler:ApiResponseHandler<Array<Message>>):AppThunk {
    return (dispatch, getState) => {
        const chatState = getState().ChatState;

        const handler:ApiResponseHandler<Array<Message>> = {
            onSuccess: (messages) => {
                dispatch(appendChatMessages(messages));
                callbackHandler.onSuccess(messages);
            },
            onError: (error:string) => {
                dispatch(appendChatMessages([
                    ...chatState.activeChat.messages, 
                    message,
                    {
                        role: 'assistant', 
                        content:[{type : 'text', text : "I was not able to respond. Please try again."}]
                    }
                ]));
                callbackHandler.onError(error);
            },
            onComplete: () => callbackHandler.onComplete?.()
        }

        ChatService.sendChatRequest(token, chatState.activeChat.messages, handler)
    }
}

export function saveChat(token: string, messages:Array<Message>, callbackHandler:ApiResponseHandler<Chat>):AppThunk {
    return (dispatch, getState) => {
        const chatState = getState().ChatState;

        const handler:ApiResponseHandler<Chat> = {
            onSuccess: (savedChat) => {
                dispatch(updateActiveChat(savedChat));
                callbackHandler.onSuccess(savedChat);
            },
            onError: (error:string) => callbackHandler.onError(error),
            onComplete: () => callbackHandler.onComplete?.()
        }

        const request:Chat = {
            ...chatState.activeChat,
            messages
        }

        ChatService.saveChat(token, request, handler)
    }
}