import React from 'react';
import ChatList from './chatList';
import MBFlyout from '../flyout/mbFlyout';

export interface ChatListFlyoutProps {
    onClose: () => void;
}

const ChatListFlyout: React.FC<ChatListFlyoutProps> = (props) => {
    const { onClose } = props;

    return (
        <MBFlyout title="Chat History" onClose={onClose}>
            <ChatList onChatSelected={onClose} />
        </MBFlyout>
    );
};

export default ChatListFlyout;